/**
 * 上市公司公告
 */
import { ReqPage } from '@/model/common/reqPage'
export class AnnouncementClass {
  id: string | undefined // "string //主键"
  announcementName: string | undefined // 公告名称
  customerName: string | undefined // 公司名称
  parentId: number | undefined //  公司id
  industry: string | undefined // 外部行业
  innerIndustry: string | undefined // 内部行业
  releaseTime: string | undefined // 发布时间
  isDeleted: string | undefined //  	是否删除;undeleted：未删除 deleted：已删除
  createdBy: string | undefined // 创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 	更新时间
  detail: string | undefined // 详情
  isFollow: 0 | 1 | undefined // 0：未关注  1：关注
}
export class ReqAnnouncementClass extends ReqPage {
  data: AnnouncementClass
  constructor() {
    super()
    this.data = new AnnouncementClass()
  }
}

export interface RegAnnouncementList extends ReqPage {
  data: AnnouncementClass[]
}
