<template>
  <a-button class="hover" v-if="icon || icon === null" :class="type || 'default'">
    <template #icon>
      <!-- <svg class="icon" aria-hidden="true">
        <use :xlink:href="'#' + icon" /></svg
    > -->
      <i class="iconfont icon" :class="icon"></i>
    </template>
    {{ text }}
  </a-button>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'iconButton',
  props: {
    icon: {
      type: String, //按钮的图标
    },
    text: {
      type: String, //按钮的文案
    },
    type: {
      type: String, //按钮颜色 primary danger warning default
    },
  },
})
</script>
<style lang="scss" scoped>
.ant-btn {
  min-width: 82px;
  height: 32px;
  margin-right: 20px;
  padding: 12 25px;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  border: none;
  .icon {
    margin-right: 11px;
    vertical-align: middle;
  }
}
.primary {
  background-color: #13c2c2;
}
.danger {
  background-color: #f96868;
}
.warning {
  background-color: #ff972f;
}
.default {
  background-color: #41a3fd;
}
.stop {
  background-color: #333333;
}
</style>
